import clients from '@/api/clients';

const state = () => ({
  userCompany: localStorage.getItem('user-company') || '',
  cashierList: [],
  companyList: [],
  counterList: [],
  employeeList: [],
  stockList: [],

  loadingStates: new Set(),
})

const defaultCashier = [
  { text: 'Все кассы', value: "" },
]

const defaultCompany = [
  { text: 'Все компании', value: "" },
]

const defaultStock = [
  { text: 'Все склады', value: "" },
]

const getters = {
  getCountryList: () => [
    { text: "Белорусия", value: "BY" },
    { text: "Казахстан", value: "KZ" },
    { text: "Россия", value: "RU" },
  ],
  getCashierList: state => [...defaultCashier, ...state.cashierList],
  getCompanyList: state => [...defaultCompany, ...state.companyList.map(el => ({ text: el.name, value: el.id }))],
  getCounterList: state => state.counterList,
  getCompanyWithTownList: state => [...defaultCompany, ...state.companyList.map(el => ({ text: el.city ? `${el.name} (${el.city})` : el.name, value: el.id }))],
  getEmployeeList: state => (isShowAll = true) => isShowAll ? state.employeeList : state.employeeList.filter(el => el.is_active === true),
  // Для Менеджера маркетплейсов в селектор Склады наименование "Все склады" не включаем, для Менеджера маркетплейсов должен быть обязательно конкретный склад
  getStockList: state => (isMarketplaceManager = true) => isMarketplaceManager ? state.stockList : [...defaultStock, ...state.stockList],
  getUserCompany: state => Number.isInteger(Number.parseInt(state.userCompany)) ? Number.parseInt(state.userCompany) : "",
  showCompanySelect: state => state.companyList.length > 1,
  isCashierListLoading: state => !!state.loadingStates && state.loadingStates.has("cashier"),
  isCompanyListLoading: state => !!state.loadingStates && state.loadingStates.has("company"),
  isCounterListLoading: state => !!state.loadingStates && state.loadingStates.has("counter"),
  isStockListLoading: state => !!state.loadingStates && state.loadingStates.has("stock"),
}

const actions = {
  updateCashierList({ commit, getters }) {
    commit("addLoadingState", "cashier");
    clients.getCashierList(getters.getUserCompany).then(
      response => {
        commit('setCashierList', response.data);
        commit("removeLoadingState", "cashier");
      }
    );
  },
  updateCompanyList({ commit }) {
    commit("addLoadingState", "company");
    clients.getCompanyList().then(
      response => {
        commit('setCompanyList', response.data);
        commit("removeLoadingState", "company");
      }
    );
  },
  updateCounterList({ commit, getters }) {
    commit("addLoadingState", "counter");
    clients.getCounterList(getters.getUserCompany).then(
      response => {
        commit('setCounterList', response.data);
        commit("removeLoadingState", "counter");
      }
    );
  },
  updateEmployeeList({ commit, getters }) {
    commit("addLoadingState", "employee");
    clients.getEmployeeList(getters.getUserCompany).then(
      response => {
        commit('setEmployeeList', response.data);
        commit("removeLoadingState", "employee");
      }
    );
  },
  updateStockList({ commit, getters }) {
    commit("addLoadingState", "stock");
    clients.getStockList(getters.getUserCompany).then(
      response => {
        commit('setStockList', response.data);
        commit("removeLoadingState", "stock");
      }
    );
  },
  updateStockListFromOrderEdit({ commit, getters }) {
    commit("addLoadingState", "stock");
    return clients.getStockList(getters.getUserCompany)
      .then(response => {
        commit('setStockList', response.data);
        commit("removeLoadingState", "stock");
      })
      .catch(error => {
        console.error("Failed to get stock list:", error);
        commit("removeLoadingState", "stock");
      });
  }
}
const mutations = {
  setCashierList(state, payload) {
    state.cashierList = [];
    payload.forEach(el => {
      state.cashierList.push({ text: el.name, value: el.id })
    });
  },
  setCompanyList(state, payload) {
    state.companyList = payload;
  },
  setCounterList(state, payload) {
    state.counterList = [];
    payload.forEach(el => {
      state.counterList.push({ ...el, text: el.name, value: el.id })
    });
  },
  setEmployeeList(state, payload) {
    state.employeeList = [];
    payload.forEach(el => {
      state.employeeList.push(el)
    });
  },
  setStockList(state, payload) {
    state.stockList = [];
    payload.forEach(el => {
      state.stockList.push({ text: el.name, value: el.id })
    });
  },
  setUserCompany(state, payload) {
    state.userCompany = payload;
    localStorage.setItem('user-company', payload);
  },
  addLoadingState(state, name) {
    state.loadingStates.add(name);
  },
  removeLoadingState(state, name) {
    state.loadingStates.delete(name);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
