import axios from "axios";
import * as Sentry from "@sentry/vue";

import router from "@/router";
import store from "@/store";

const isNetworkError = error => !!error.isAxiosError && !error.response;

const api = axios.create({
  "baseURL": process.env.VUE_APP_API_ROOT || "http://127.0.0.1:8000/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user-token") ? `Token ${localStorage.getItem("user-token")}` : "",
  }
});

api.interceptors.request.use(
  request => {
    if (request && request.data) {
      Sentry.setContext("request", {
        data: request.data
      });
    }
    return request;
  },
);

api.interceptors.response.use(response => {
  return response;
}, error => {
  // Проверяем, что это не ошибка соединения с сетью
  if (isNetworkError(error)) {
    store.dispatch("alertError", "Ошибка соединения с сервером. Проверьте своё интернет-соединение и обновите страницу.");
    return Promise.reject(error);
  }

  if (error.response && error.response.data) {
    Sentry.setContext("response", {
      data: error.response.data
    });
  }

  let path = null;
  switch (error.response.status) {
    case 400: break;
    case 401: store.dispatch("auth/AUTH_LOGOUT").then(() => router.push("/login")); break;
    case 403: path = "/403"; break;
    case 404: path = "/404"; break;
    case 409: error.response.data.non_field_errors.forEach(element => {
      store.dispatch("alertError", element);
    }); break;
    case 500: path = "/500"; break;
  }

  if (error.response.data && error.response.data.detail) store.dispatch("alertError", error.response.data.detail);
  if (path) router.push(path);
  return Promise.reject(error);
});

export default api;
