import { render, staticRenderFns } from "./TheCompanySelect.vue?vue&type=template&id=c04a3fa0&scoped=true&"
import script from "./TheCompanySelect.vue?vue&type=script&lang=js&"
export * from "./TheCompanySelect.vue?vue&type=script&lang=js&"
import style0 from "./TheCompanySelect.vue?vue&type=style&index=0&id=c04a3fa0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c04a3fa0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
