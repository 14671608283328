import api from '@/api';

const account_user_path = '/account/user/get_user/';
const account_company_path = '/account/company/';
const get_user_company = '/account/company/get_user_company/';
const account_stock_path = '/account/stock/';

const wrapPromise = (promise) =>
  new Promise((resolve, reject) =>
    promise
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  );

export default {
  getAccountUserInfo() {
    return wrapPromise(api.get(account_user_path));
  },
  changeAccountUserInfo(payload) {
    return wrapPromise(
      api.patch(`account/user/${payload.id}/update_user/`, payload)
      );
  },
  createAccountPartner(payload) {
    return wrapPromise(api.post(account_user_path, payload));
  },
  getAccountCompanyInfo() {
    return wrapPromise(api.get(get_user_company));
  },
  changeAccountCompanyInfo(payload) {
    return wrapPromise(
      api.patch(`${account_company_path}${payload.id}/`, payload)
      );
  },
  createAccountCompanyInfo(payload) {
    return wrapPromise(api.post(account_company_path, payload));
  },
  getAccountStocksInfo() {
    return wrapPromise(api.get(account_stock_path + 'get_stock/'));
  },
  changeAccountStockInfo(payload) {
    return wrapPromise(
      api.patch(`${account_stock_path}${payload.id}/`, payload)
      );
  },
  createAccountStockInfo(payload) {
    return wrapPromise(api.post(account_stock_path, payload));
  },
  deleteAccountStockInfo(payload) {
    return wrapPromise(api.delete(`${account_stock_path}${payload}/`));
  },
  getCompanyList() {
    return wrapPromise(api.get('account/company/'));
  },
  getCompany(id) {
    return wrapPromise(api.get(`account/company/${id}/`));
  },
  getUserList() {
    return wrapPromise(api.get('account/user/'));
  },
  getUser(id) {
    return wrapPromise(api.get( `account/user/${id}/`));
  },
  changeUserInfo(payload) {
    return wrapPromise(api.put( `account/user/${payload.id}/`, payload));
  },
  createUserInfo(payload) {
    return wrapPromise(api.post( 'account/user/', payload));
  },
  getWarehousesList() {
    return wrapPromise(api.get(account_stock_path));
  },
  getWarehouse(id) {
    return wrapPromise(api.get(`${account_stock_path}${id}/`));
  },
  changeWarehouseInfo(payload) {
    console.log(payload)
    return wrapPromise(api.put(`${account_stock_path}${payload.id}/`, payload));
  },
  createWarehouseInfo(payload) {
    return wrapPromise(api.post(account_stock_path, payload));
  },
  adminResetPassword(id, password) {
    return wrapPromise(api.post(`account/user/${id}/admin_reset_password/`, {'new_password': password}));
  }
};
