<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
  >
    <v-list
      v-if="$vuetify.breakpoint.mobile"
      dense
    >
      <v-list-item
        v-for="(link, index) in sortMenu(generateMobileMenu())"
        :key="index"
        :to="{name: link.name}"
        link
      >
        <v-list-item-content>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <the-company-select
      v-if="$vuetify.breakpoint.mobile"
      class="pa-2"
    />

    <template
      v-if="$vuetify.breakpoint.mobile"
      #append
    >
      <div class="pa-2">
        <v-btn
          color="primary"
          text
          block
          @click="logout"
        >
          Выйти
        </v-btn>
      </div>
    </template>

    <div
      v-if="!$vuetify.breakpoint.mobile"
      style="height: 100%;"
    >
      <v-card
        v-for="(link, index) in sortMenu(generateDesktopMenu())"
        :key="index"
        tile
        flat
        class="px-2 py-1 sidebar__card-outer"
      >
        <v-card
          :to="{name: link.name}"
          class="sidebar__card-inner"
          active-class="sidebar__card-active"
        >
          <v-icon>{{ link.icon }}</v-icon>
          <v-card-title v-text="link.title"/>
        </v-card>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TheCompanySelect from "@/components/TheCompanySelect";

export default {
  name: "Sidebar",

  components: {
    TheCompanySelect
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getUserGroup", "isFranchiseeGroup", "isWholeSaleGroup",
      "isWholesaleHunter", "isWholesaleFarmer", "isWholesalePartner", "isPurchasingManager",
      "isWholesaleROP", "isDirectorOfFranchising", "isSuperUser", "isAdmin", "isStorekeeper", "isFranchisee"]),
    drawer: {
      get() {
        return !this.$vuetify.breakpoint.mobile
          ? true
          : this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("setDrawer", value);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["AUTH_LOGOUT"]),
    ...mapActions("stocks", ["clearOrder"]),

    generateMobileMenu: function () {
      return [
        {
          name: "NewsList",
          title: "Новости",
          isWholesaleHunter: false,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
        },
        {
          name: "FolderList",
          title: "Файлы",
          isWholesaleHunter: false,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
        },
        {
          name: "EducationList",
          title: "Обучение",
          isWholesaleHunter: true,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
        },
        {
          name: "StocksView",
          title: this.isPurchasingManager ? "Отгрузка в магазины УК" : "Заказ товара",
        },
        {
          name: "FinanceView",
          title: "Выручка",
          isWholesaleHunter: false,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
        },
        {
          name: "RatingView",
          title: "Рейтинг магазинов",
          isPurchasingManager: false,
        },
        {
          name: "OrderList",
          title: "Мои заказы",
        },
        {
          name: "TableView",
          title: "Табель",
          isWholesaleHunter: false,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
        },
        // {
        //   name: "OrderAdminList",
        //   title: "Управление заказами",
        //   isWholesaleHunter: false,
        //   isWholesaleFarmer: false,
        //   isWholesalePartner: false,
        //   isFranchisee: false,
        //   isPurchasingManager: false,
        // },
      ]
    },
    generateDesktopMenu: function () {
      return [
        {
          name: "EducationList",
          title: "Обучение",
          isWholesaleHunter: true,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
          isStorekeeper: false,
        },
        {
          name: "StocksView",
          title: this.isPurchasingManager ? "Отгрузка в магазины УК" : "Заказ товара",
          isStorekeeper: false,
        },
        {
          name: "FinanceView",
          title: "Выручка",
          isWholesaleHunter: false,
          isWholesaleFarmer: false,
          isWholesalePartner: false,
          isWholesaleROP: false,
          isPurchasingManager: false,
          isStorekeeper: false,
        },
        {
          name: "RatingView",
          title: "Рейтинг магазинов",
          isPurchasingManager: false,
          isStorekeeper: false,
        },
        {
          name: "OrderList",
          title: "Мои заказы",
          isStorekeeper: false,
        },
        // {
        //   name: "OrderAdminList",
        //   title: "Управление заказами",
        //   isWholesaleHunter: false,
        //   isWholesaleFarmer: false,
        //   isWholesalePartner: false,
        //   isFranchisee: false,
        //   isPurchasingManager: false,
        // },
      ]
    },

    logout: function () {
      this.clearOrder();
      this.AUTH_LOGOUT().then(() => this.$router.push("/login"));
    },
    sortMenu: function (menu) {
      return menu.filter((el) =>
        (!("isFranchiseeGroup" in el) || el.isFranchiseeGroup === this.isFranchiseeGroup) &&
        (!("isWholeSaleGroup" in el) || el.isWholeSaleGroup === this.isWholeSaleGroup) &&
        (!("isPurchasingManager" in el) || el.isPurchasingManager === this.isPurchasingManager) &&
        (!("isWholesaleHunter" in el) || el.isWholesaleHunter === this.isWholesaleHunter) &&
        (!("isWholesaleFarmer" in el) || el.isWholesaleFarmer === this.isWholesaleFarmer) &&
        (!("isWholesalePartner" in el) || el.isWholesalePartner === this.isWholesalePartner) &&
        (!("isWholesaleROP" in el) || el.isWholesaleROP === this.isWholesaleROP) &&
        (!("isStorekeeper" in el) || el.isStorekeeper === this.isStorekeeper) &&
        (!("isSuperUser" in el) || el.isSuperUser === this.isSuperUser) &&
        (!("isDirectorOfFranchising" in el) || el.isDirectorOfFranchising === this.isDirectorOfFranchising) &&
        (!("isFranchisee" in el) || el.isFranchisee === this.isFranchisee)
      );
    },
  },
};
</script>

<style scoped>
.sidebar__card-inner {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar__card-outer {
  width: 100%;
  height: 20%;
}

.sidebar__card-active {
  background-color: rgba(0, 0, 0, 0.14);
}
</style>
