<template>
  <v-app-bar
    app
    dark
    clipped-left
    color="black"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click.stop="changeDrawerState"
    />
    <v-img
      class="mx-2 mr-5"
      :src="require('@/assets/logo.png')"
      max-width="125"
      contain
    />
    <template v-if="!$vuetify.breakpoint.mobile">
      <v-btn
        v-for="(item, index) in sortMenu(desktopMenu)"
        :key="index"
        :exact="item.exact"
        :to="{ name: item.link }"
        text
        class="mr-2"
      >
        <v-icon
          dark
          left
        >
          {{ item.icon }}
        </v-icon>
        <span>{{ item.title }}</span>
      </v-btn>
<!--      <template>-->
<!--        <div class="text-center">-->
<!--          <v-menu-->
<!--            open-on-hover-->
<!--            top-->
<!--            offset-y-->
<!--            v-if="sortAdminMenu(adminMenu).length > 0"-->
<!--          >-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-btn-->
<!--                dark-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--              >-->
<!--                Настройки-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item-->
<!--                v-for="(item, index) in sortAdminMenu(adminMenu)"-->
<!--                :key="index"-->
<!--              >-->
<!--                <router-link :to="{ name: item.link}">-->
<!--                  <v-list-item-title>{{ item.title }}</v-list-item-title>-->
<!--                </router-link>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->
<!--        </div>-->
<!--      </template>-->
      <v-spacer/>
      <wholesale-invite-widget v-if="this.isWholesaleHunter || this.isWholesaleROP"/>
      <the-company-select/>
      <v-btn
        :to="{ name: 'AccountView'}"
        text
        class="mr-2 text-capitalize"
      >
        <v-icon
          dark
          left
        >
          mdi-account
        </v-icon>
        <span>{{ this.getHeaderUN }}</span>
      </v-btn>
      <v-btn
        text
        @click="logout"
      >
        <span>Выйти</span>
      </v-btn>
    </template>
    <template
      v-if="needToFulfillInfo"
      v-slot:extension
    >
      <v-container
        fluid
        class="pa-0 ma-0"
        rounded
        style="background-color: #D32F2F"
      >
        <p class="text-center pa-0 ma-2">
          Ваш аккаунт не активирован. Пожалуйста, заполните информацию о своей компании в
          <router-link style="color: white" tag="a" :to="{ name: 'AccountView'}">личном кабинете.</router-link>
        </p>
      </v-container>
    </template>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TheCompanySelect from "@/components/TheCompanySelect";
import WholesaleInviteWidget from "@/components/WholesaleInviteWidget.vue";

export default {
  name: "Header",
  components: {
    WholesaleInviteWidget,
    TheCompanySelect,
  },
  data: () => ({
    desktopMenu: [
      {
        link: "MainPage",
        title: "Главная",
        icon: "mdi-home",
        exact: true
      },
      {
        link: "NewsList",
        title: "Новости",
        icon: "mdi-newspaper",
        isWholeSaleGroup: false,
        isPurchasingManager: false,
      },
      {
        link: "FolderList",
        title: "Файлы",
        icon: "mdi-folder",
        isWholeSaleGroup: false,
        isPurchasingManager: false,
      },
      {
        link: "TableView",
        title: "Табель",
        icon: "mdi-table",
        isWholeSaleGroup: false,
        isPurchasingManager: false,
      },
    ],
    adminMenu: [
      {
        link: "CompanyList",
        title: "Компании",
        isSuperUser: true,
        isAdmin: true,
        isDirectorOfFranchising: true,
        isWholesaleROP: true,
      },
      {
        link: "UserList",
        title: "Пользователи",
        isSuperUser: true,
        isAdmin: true,
        isDirectorOfFranchising: true,
        isWholesaleROP: true,
      },
      {
        link: "WarehouseList",
        title: "Склады",
        isSuperUser: true,
        isAdmin: true,
        isDirectorOfFranchising: true,
        isWholesaleROP: true,
      },
    ],
    partnerMail: "",
  }),
  computed: {
    ...mapGetters("auth", ["getUsername", "getUserGroup", "isWholeSaleGroup", "isFranchiseeGroup", 'isWholesaleHunter', 'isWholesaleROP', 'needToFulfillInfo', "userFN", "companyName", "isStaff", "isPurchasingManager", "isDirectorOfFranchising", "isSuperUser", "isAdmin"]),
    getHeaderUN() {
      if (this.isStaff) {
        return this.userFN
      } else {
        return `${this.userFN} (${this.companyName})`
      }
    },
  },
  methods: {
    ...mapActions("auth", ["AUTH_LOGOUT"]),
    ...mapActions("stocks", ["clearOrder"]),
    ...mapActions(["changeDrawerState"]),

    sortAdminMenu: function (){
      return this.adminMenu.filter((el) => {
        const { isDirectorOfFranchising, isWholesaleROP, isSuperUser, isAdmin } = el;
        if (this.isDirectorOfFranchising && isDirectorOfFranchising) return true;
        if (this.isWholesaleROP && isWholesaleROP) return true;
        if (this.isSuperUser && isSuperUser) return true;
        if (this.isAdmin && isAdmin) return true;
        return !isDirectorOfFranchising && !isWholesaleROP && !isSuperUser && !isAdmin;
      });
    },


    logout: function () {
      this.clearOrder(); // При выходе очищаем информацию о том, что мы редактировали какую-либо заявку
      this.AUTH_LOGOUT().then(() => this.$router.push("/login"));
    },
    sortMenu: function (menu) {
      menu = this.isFranchiseeGroup
        ? menu.filter((el) => el.isFranchiseeGroup !== false)
        : menu.filter((el) => el.isFranchiseeGroup !== true);
      menu = this.isWholeSaleGroup
        ? menu.filter((el) => el.isWholeSaleGroup !== false)
        : menu.filter((el) => el.isWholeSaleGroup !== true);
      menu = this.isPurchasingManager
        ? menu.filter((el) => el.isPurchasingManager !== false)
        : menu.filter((el) => el.isPurchasingManager !== true);
      return menu;
    },
  },
};
</script>
