<template>
  <v-select
    v-if="showCompanySelect"
    v-model="userCompany"
    :items="getCompanyWithTownList"
    outlined
    dense
    hide-details
    class="navbar__company-select"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapGetters("auth", ["isStaff"]),
    ...mapGetters("clients", [
      "getCompanyWithTownList",
      "getUserCompany",
      "showCompanySelect",
    ]),
    userCompany: {
      get() {
        return {
          value: this.getUserCompany,
          text: this.getCompanyWithTownList.find(
            (el) => el.value == this.getUserCompany
          ).text,
        };
      },
      set(value) {
        this.alertSuccess("Выбрана другая компания");
        this.$store.commit("setDrawer", false);
        this.$store.commit("clients/setUserCompany", value);
        this.updateCashierList();
        this.updateCounterList();
        this.updateEmployeeList();
        this.updateStockList();
      },
    },
  },
  watch: {
    isStaff(v) {
      if (v) this.updateCompanyList();
    },
  },
  created() {
    if (this.isStaff) this.updateCompanyList();
    this.$root.$on("changeUserCompany", (value) => {
      this.changeUserCompanyMethod(value);
      this.updateCompanyList();
    });
  },
  methods: {
    ...mapActions(["alertSuccess"]),
    ...mapActions("clients", [
      "updateCashierList",
      "updateCompanyList",
      "updateCounterList",
      "updateEmployeeList",
      "updateStockList",
    ]),
    changeUserCompanyMethod(value) {
      this.userCompany = value;
    },
  },
};
</script>

<style scoped>
.navbar__company-select {
  flex: none;
  width: 15em;
  margin-right: 0.5em;
}
</style>
