import api from '@/api';

export default {
  getCashierList(company = "") {
    return new Promise((resolve, reject) => {
      api
        .get('/clients/cashier/', { params: { company } })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getCompanyList() {
    return new Promise((resolve, reject) => {
      api
        .get('/clients/company/')
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getWholesaleCompanyList() {
    return new Promise((resolve, reject) => {
      api
        .get('/clients/company/wholesalers/')
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getCounterList(company = "") {
    return new Promise((resolve, reject) => {
      api
        .get('/clients/counter/', { params: { company } })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getEmployeeList(company = "") {
    return new Promise((resolve, reject) => {
      api
        .get('/clients/employee/', { params: { company } })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getStockList(company = "") {
    return new Promise((resolve, reject) => {
      api
        .get('/clients/stock/', { params: { company } })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  createEmployee(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .post(`/clients/employee/`, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  updateEmployee({ id, is_active } = {}) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/clients/employee/${id}/`, { is_active })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
