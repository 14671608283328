import router from '@/router';
import stocks from '@/api/stocks';


const state = {
  items: JSON.parse(localStorage.getItem('user-cart') || '[]'),
  stocks: [],

  isLoading: true,

  orderInfo: null,
  orderId: (!localStorage.getItem('user-order-id') || localStorage.getItem('user-order-id') == 'null') ? null : localStorage.getItem('user-order-id'),
  orderFromChina: JSON.parse(localStorage.getItem('user-order-from-china') || 'null'),
  stockTo: "",
  orderType: "",
  soldDateRange: null,
}

const getters = {
  getOrderId: state => state.orderId,
  getOrderInfo: state => state.orderInfo,
  getOrderFromChina: state => state.orderFromChina,
  getStockTo: state => state.stockTo,
  getSoldDateRange: state => state.soldDateRange,
  getOrderType: state => state.orderType,
  getItemsForPost: state => {
    return state.items.map((item) => {
      let combinedItem = {...item, ...state.stocks.find((el) => el.id === item.product)};
      return {quantity: combinedItem.order, product: combinedItem.product};
    });
  },
  getItems: state => {
    let items = state.items.map((item) => ({ ...item, ...state.stocks.find((el) => el.id === item.product) }));
    items = items.filter(item => item.id);
    return (state.stocks.length !== 0 && state.items !== 0) ? items.map((item) => ({ ...item, amount: item.order * item.price })) : [];
  },
  getStocks: state => state.stocks.map(el => ({
    ...el, new: Date.parse(el.created_at) > new Date(new Date().setDate(new Date().getDate() - 183))
  })),
  getLoadingState: state => state.isLoading,
  getQuantity: (state, getters) => {
    if (getters.getItems.length === 0) return 0;
    return getters.getItems.reduce((acc, value) => acc + Number(value.order), 0);
  },
  getAmount: (state, getters) => {
    if (getters.getItems.length === 0) return 0;
    return getters.getItems.reduce((acc, value) => acc + Number(value.order) * Number(value.price), 0);
  }
}

const actions = {
  pushItems({ commit, state }, { payload }) {
    payload.forEach((item) => {
      if (!Number.isFinite(item.product)) return;
      let id = state.items.length === 0 ? -1 : state.items.findIndex((el) => el.product === item.product);
      commit('pushItems', { item: { product: Number(item.product), order: Number(item.order) }, id });
    });
  },
  clearItems(state) {
    state.commit('clearItems');
  },
  createOrder({ getters, rootGetters, dispatch }, { stock, wholesaleMail, company}) {
  return new Promise((resolve, reject) => {
    stocks.postOrder({
      items: getters.getItemsForPost,
      company: company || rootGetters['clients/getUserCompany'],
      stock_to: stock,
      from_china: getters.getOrderFromChina,
      is_marketplace: rootGetters['auth/isMarketplaceManager'],
      is_purchasing: rootGetters['auth/isPurchasingManager'],
      type: rootGetters['auth/isMarketplaceManager'] ? getters.getOrderType : null,
      wholesale_email: wholesaleMail
    })
    .then(response => {
      dispatch('clearOrder');
      if (!wholesaleMail) {
        dispatch('alertSuccess', 'Заказ успешно создан', { root: true });
      } else {
        dispatch('alertSuccess', 'Партнёру ОПТ отправлено приглашение и заказ', { root: true });
      }
      router.push({ name: 'OrderView', params: { id: response.data.id } });
      resolve(response);
    })
    .catch(error => {
      console.log(error.response.data);
      if (error.response.data.items) {
        error.response.data.items.forEach(el => dispatch('alertError', el, { root: true }));
      }
      reject(error);
    });
  });
},
  updateOrder({ getters, dispatch }, { orderId, stock }) {
    stocks.putOrder(
      // FIXME: проверять, что сохраняем тот заказ, который в сторе
      { id: orderId, items: getters.getItemsForPost, stock_to: stock }).then(
        response => {
          router.push({ name: 'OrderView', params: { id: response.data.id } });
          dispatch('alertSuccess', 'Заказ успешно сохранен', { root: true });
          dispatch('clearOrder');
        }
      ).catch(error => {
        if (error.response.data.items) error.response.data.items.forEach(el => dispatch('alertError', el, { root: true }));
      });
  },
  getOrder({ commit, dispatch, getters }, { orderId }) {
    commit('setLoading', true);
    if (getters['getOrderId'] !== orderId) {
      commit('purgeItems');
    }
    stocks.getOrder(orderId).then(
      response => {
        if (getters['getOrderId'] !== orderId) {
          dispatch('pushItems', { payload: response.data.items.map(el => ({ product: el.product.id, order: el.quantity })), is_notify: false });
        }
        commit('setOrderInfo', response.data);
        commit('setOrderFromChina', response.data.from_china);
        commit('setOrderType', response.data.type);
        commit('setStockTo', response.data.stock_to);
        commit('setLoading', false);
      }
    );
  },
  updateStockItems({ commit, rootGetters }, { stock, sold_date__gte, sold_date__lte } = {}) {
    return new Promise((resolve) => {
      commit('setLoading', true);
      commit('setStocks', []);
      let company = rootGetters['clients/getUserCompany'];
      stocks.getAllStocks({ company, stock, sold_date__gte, sold_date__lte }).then(
        response => {
          commit('setStocks', response.data);
          commit('setLoading', false);
          resolve();
        }
      );
    })
  },
  setLoading({ commit }, state) {
    commit('setLoading', state);
  },
  setOrderId({ commit }, orderId) {
    commit('setOrderId', orderId);
  },
  setOrderFromChina({ commit }, state) {
    commit('setOrderFromChina', state);
  },
  setStockTo({ commit }, state) {
    commit('setStockTo', state);
  },
  setSoldDateRange({ commit }, range) {
    commit('setSoldDateRange', range);
  },
  setOrderType({ commit }, state) {
    commit('setOrderType', state);
  },
  removeItem({ commit }, index) {
    commit('removeItem', index);
  },
  clearOrder({ commit }) {
    commit('purgeItems');
    commit('setOrderInfo', null);
    commit('removeOrderId');
    commit('setOrderFromChina', null);
    commit('setStockTo', "");
    commit('setOrderType', "");
  }
}

const mutations = {
  setOrderInfo(state, payload) {
    state.orderInfo = payload;
  },
  setOrderId(state, payload) {
    state.orderId = payload;
    localStorage.setItem('user-order-id', payload);
  },
  setOrderFromChina(state, payload) {
    state.orderFromChina = payload;
    localStorage.setItem('user-order-from-china', payload);
  },
  setStockTo(state, payload) {
    state.stockTo = payload;
  },
  setSoldDateRange(state, payload) {
    state.soldDateRange = payload;
  },
  setOrderType(state, payload) {
    state.orderType = payload;
  },
  pushItems(state, { item, id }) {
    if (id === -1) {
      state.items.push(item);
    } else {
      state.items.splice(id, 1, item);
    }
    localStorage.setItem('user-cart', JSON.stringify(state.items));
  },
  clearItems(state) {
    state.items = [];
  },
  removeItem(state, index) {
    state.items.splice(index, 1);
    localStorage.setItem('user-cart', JSON.stringify(state.items));
  },
  purgeItems(state) {
    state.items = [];
    localStorage.removeItem('user-cart');
  },
  setStocks(state, payload) {
    state.stocks = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  removeOrderId(state) {
    state.orderId = null;
    localStorage.removeItem('user-order-id');
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
