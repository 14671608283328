import Vue from 'vue';

const dateformat = Vue.filter('dateformat', function (value, time = true) {
  let options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  if (time) options = {
    ...options, hour: 'numeric',
    minute: 'numeric'
  };
  if (value) return new Date(Date.parse(value)).toLocaleString('ru', options);
})

const currency = Vue.filter('currency', function (value, currency = 'RU') {
  const options = {
    minimumFractionDigits: 2
  };
  const PREFIX_MAP = {
    'RU': '',
    'USD': '$',
    'BY': '',
    'KZ': '',
  }
  const SUFFIX_MAP = {
    'RU': ' р.',
    'USD': '',
    'BY': ' Br',
    'KZ': ' тенге',
  }
  const prefix = PREFIX_MAP[currency];
  const suffix = SUFFIX_MAP[currency];
  if (window.isFinite(value)) return `${prefix}${Number(value).toLocaleString('ru', options)}${suffix}`;
})

const file_size = Vue.filter('file_size', function (value) {
  const file_size = Number.parseInt(value);

  if (isNaN(file_size)) return "-";

  const GB = 1024 * 1024 * 1024;
  const MB = 1024 * 1024;
  const KB = 1024;
  const number_options = {
    maximumFractionDigits: 2
  };

  if (file_size > GB) return Number(file_size / GB).toLocaleString('ru', number_options) + ' Гбайт';
  if (file_size > MB) return Number(file_size / MB).toLocaleString('ru', number_options) + ' Мбайт';
  if (file_size > KB) return Number(file_size / KB).toLocaleString('ru', number_options) + ' Кбайт';
  return Number(file_size / KB).toLocaleString('ru', number_options) + ' байт';
})

export default {
  dateformat,
  currency,
  file_size
}
