<template>
  <v-text-field
    v-model="partnerMail"
    :append-icon="partnerMail ? 'mdi-send' : ''"
    :rules="emailRules"
    filled
    type="text"
    placeholder="Пригл. ОПТ-партнер"
    outlined
    dense
    hide-details
    @click:append="inviteWholesalePartner"
    class="navbar__invite-widget"
  ></v-text-field>
</template>

<script>
import account from "@/api/account";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WholesaleInviteWidget",
  data() {
    return {
      partnerMail: "",
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserGroup"]),
    invitedPartner() {
      return {
        inviter_role: this.getUserGroup,
        email: this.partnerMail,
      }
    },
    emailRules() {
      return [
        v => !!v || 'Это поле нужно заполнить',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || 'Неправильный формат почты'
      ]
    },

  },
  methods: {
    ...mapActions(["changeDrawerState", "alertSuccess", 'alertInfo']),
    inviteWholesalePartner() {
      account.createAccountPartner(this.invitedPartner).then(() => {
        this.partnerMail = "";
        this.alertSuccess("Приглашение отправлено");
      }).catch(() => {
        this.alertInfo("Такой пользователь уже существует");
      })
    },
  }
}

</script>

<style scoped>
.navbar__invite-widget {
  flex: none;
  margin-right: 0.5em;
}
</style>
