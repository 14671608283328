import * as Sentry from "@sentry/vue";

import api from '@/api';
import auth from '@/api/auth';

const state = {
  token: localStorage.getItem('user-token') || '',
  loginError: '',
  userinfo: {},
}

const getters = {
  getUsername: state => state.userinfo.username || "",
  getUseCountry: state => state.userinfo.country || "RU",
  getUserGroup: state => state.userinfo.group,
  isAuthenticated: state => !!state.token,
  isStaff: state => !!state.userinfo.is_staff,
  isMarketplaceManager: state => state.userinfo.group === 10, /* Менеджером маркетплейсов */
  isWholesaleHunter: state => state.userinfo.group === 11, /* Менеджер ОПТ Хантер */
  isWholesalePartner: state => state.userinfo.group === 12, /* Партнер ОПТ */
  isWholesaleROP: state => state.userinfo.group === 13, /* РОП ОПТ */
  isWholesaleFarmer: state => state.userinfo.group === 14, /* Менеджер ОПТ Фермер */
  isPurchasingManager: state => state.userinfo.group === 8, /* Менеджер по закупкам */
  isSuperUser : state => state.userinfo.group === 1, /* Суперпользователь */
  isAdmin: state => state.userinfo.group === 2, /* Администратор */
  isDirectorOfFranchising: state => state.userinfo.group === 6, /* Директор франчайзинга */
  isStorekeeper: state => state.userinfo.group === 3, /* Кладовщик */
  isFranchisee: state => state.userinfo.group === 4, /* Франчайзи */
  loginError: state => state.loginError,
  getUserCompany: state => state.userinfo.company,
  getOrderMinSum: state => state.userinfo.order_min_sum, /* Определяем минимальную сумму заявки, зависит от роли
                                                        авторизованного пользователя*/
  getChinaOrderMinSum: state => state.userinfo.china_order_min_sum,
  isFranchiseeGroup: state => ([4, 6, 10]).includes(state.userinfo.group),
  isWholeSaleGroup: state => ([11, 12, 13, 14]).includes(state.userinfo.group),
  needToFulfillInfo: state => state.userinfo.need_to_fulfill_info,
  userFN: state => state.userinfo.user_fn,
  companyName: state => state.userinfo.company_name,
}

const actions = {
  AUTH_REQUEST: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST');
      auth
        .postAuth(payload)
        .then(resp => {
          localStorage.setItem('user-token', resp.data.token);
          api.defaults.headers['Authorization'] = "Token " + resp.data.token;
          commit('AUTH_SUCCESS', resp.data.token);
          resolve(resp);
        })
        .catch(err => {
          commit('AUTH_ERROR', err);
          localStorage.removeItem('user-token');
          localStorage.removeItem('user-company');
          commit('setUserinfo', {});
          reject(err);
        })
    })
  },
  AUTH_LOGOUT: ({ commit }) => {
    return new Promise((resolve) => {
      commit('AUTH_LOGOUT');
      commit('setUserinfo', {});
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-company');
      delete api.defaults.headers['Authorization'];
      resolve();
    })
  },
  updateUserinfo: ({ commit }) => {
    auth
      .getUserinfo()
      .then(resp => {
        commit('setUserinfo', resp.data);
        Sentry.setUser({ username: resp.data.username, email: resp.data.email });
      })
      .catch(() => {
        commit('setUserinfo', {});
      })
  },

}

const mutations = {
  AUTH_REQUEST: (state) => {
    state.loginError = ''
  },
  AUTH_SUCCESS: (state, token) => {
    state.loginError = ''
    state.token = token
  },
  AUTH_ERROR: (state, error) => {
    state.loginError = error
  },
  AUTH_LOGOUT: state => {
    state.token = "";
  },
  setUserinfo: (state, data) => {
    state.userinfo = data;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
