import api from '@/api';
const auth_path = '/auth/signin/';
const userinfo_path = '/auth/userinfo/';

export default {
  getUserinfo() {
    return new Promise((resolve, reject) => {
      api
      .get(userinfo_path)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
  postAuth(payload) {
    return new Promise((resolve, reject) => {
      api
        .post(auth_path, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
