import api from '@/api';

const catalog_path = '/catalog/product/';
const category_path = '/catalog/category/';
const device_path = '/catalog/device/';

export default {
  getAllProduct() {
    return new Promise((resolve, reject) => {
      api
      .get(catalog_path)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
  getProduct(id) {
    return new Promise((resolve, reject) => {
      api
      .get(`${catalog_path}${id}/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
  getAllCategory() {
    return new Promise((resolve, reject) => {
      api
      .get(category_path)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
  getAllDevice() {
    return new Promise((resolve, reject) => {
      api
      .get(device_path)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
}
