import Vue from 'vue'
import App from './App.vue'
import filters from './filters'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import '@mdi/font/css/materialdesignicons.css';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    normalizeDepth: 5,
  });
}

Vue.component('DefaultLayout', DefaultLayout)
Vue.component('EmptyLayout', EmptyLayout)

new Vue({
  store,
  router,
  vuetify,
  filters,
  render: h => h(App)
}).$mount('#app')
