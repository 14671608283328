<template>
  <div>
    <the-sidebar />

    <the-header />

    <slot />

    <the-footer />
  </div>
</template>

<script>
  import TheFooter from "@/components/TheFooter";
  import TheHeader from "@/components/TheHeader";
  import TheSidebar from "@/components/TheSidebar";

  export default {
    name: "DefaultLayout",
    components: {
      TheFooter,
      TheHeader,
      TheSidebar,
    }
  }
</script>
