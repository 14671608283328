<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view />
    </component>

    <AlertSnackbar />
  </v-app>
</template>

<script>
import AlertSnackbar from "@/components/AlertSnackbar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AlertSnackbar,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
  watch: {
    isAuthenticated(v) {
      if (v) this.updateUserinfo();
    },
  },
  created() {
    if (this.isAuthenticated) this.updateUserinfo();
  },
  methods: mapActions("auth", ["updateUserinfo"]),
};
</script>

<style>
.v-data-table__mobile-row__cell {
  font-size: 0.775rem;
}
.v-data-table__mobile-row {
  height: 32px !important;
  min-height: none;
}
.v-tooltip__content {
  white-space: break-spaces;
}
</style>
