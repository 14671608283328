import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import catalog from '@/store/modules/catalog'
import clients from '@/store/modules/clients'
import stocks from '@/store/modules/stocks'
import account from '@/store/modules/account'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: process.env.VUE_APP_API_ROOT || 'http://127.0.0.1:8000/api/v1',
    drawer: false,
    alerts: [],
  },
  actions: {
    changeDrawerState({ commit, state }) {
      commit('setDrawer', !state.drawer);
    },
    alertSuccess({ commit }, text) {
      commit('setAlert', { text, status: 'success' });
    },
    alertInfo({ commit }, text) {
      commit('setAlert', { text, status: 'info' });
    },
    alertError({ commit }, text) {
      commit('setAlert', { text, status: 'error' });
    },
    removeAlert({ commit }, key) {
      commit('removeAlert', { key });
    },
  },
  mutations: {
    setDrawer: (state, status) => state.drawer = status,
    setAlert: (state, { text, status }) => {
      state.alerts.push({
        message: text,
        color: status,
        key: new Date(),
      })
    },
    removeAlert: (state, { key }) => {
      state.alerts.splice(state.alerts.findIndex(el => el.key === key))
    }
  },
  getters: {
    alerts: state => state.alerts,
  },
  modules: {
    auth,
    account,
    catalog,
    clients,
    stocks,
  }
})
