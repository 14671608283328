import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    redirect: {
      name: 'StocksView'
    }
  },
  {
    path: '/files',
    name: 'FolderList',
    component: () => import('@/views/files/FolderList'),
  },
  {
    path: '/files/:id',
    name: 'FolderView',
    component: () => import('@/views/files/FolderList'),
  },
  {
    path: '/news',
    name: 'NewsList',
    component: () => import('@/views/news/NewsList'),
  },
  {
    path: '/stocks',
    name: 'StocksView',
    component: () => import('@/views/stocks/StocksView'),
  },


  {
    path: '/orders',
    name: 'OrderList',
    component: () => import('@/views/stocks/OrderList'),
  },
  {
    path: '/orders/create',
    name: 'OrderCreate',
    component: () => import('@/views/stocks/OrderEdit'),
  },
  {
    path: '/orders/:id',
    name: 'OrderView',
    component: () => import('@/views/stocks/OrderView'),
  },
  {
    path: '/orders/:id/edit',
    name: 'OrderEdit',
    component: () => import('@/views/stocks/OrderEdit'),
  },


  {
    path: '/orders-admin',
    name: 'OrderAdminList',
    component: () => import('@/views/order-admin/OrderAdminList'),
  },
  {
    path: '/orders-admin/:id',
    name: 'OrderAdminView',
    component: () => import('@/views/order-admin/OrderAdminView'),
  },
  {
    path: '/orders-admin/:id/edit',
    name: 'OrderAdminEdit',
    component: () => import('@/views/order-admin/OrderAdminEdit'),
  },


  {
    path: '/educations',
    name: 'EducationList',
    component: () => import('@/views/educations/EducationView'),
  },
  {
    path: '/educations/:id',
    name: 'EducationView',
    component: () => import('@/views/educations/EducationView'),
  },
  {
    path: '/finances',
    name: 'FinanceView',
    component: () => import('@/views/finances/FinanceView'),
  },
  {
    path: '/rating',
    name: 'RatingView',
    component: () => import('@/views/finances/RatingView'),
  },
  {
    path: '/table',
    name: 'TableView',
    component: () => import('@/views/tables/TableView'),
  },
  {
    path: '/account',
    name: 'AccountView',
    component: () => import('@/views/account/AccountView'),
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/LoginView'),
    meta: {
      allowAll: true,
      onlyNotAuthenticated: true,
      layout: 'empty-layout',
    }
  },
  {
    path: '/company',
    name: 'CompanyList',
    component: () => import('@/views/companies/CompanyList'),
  },
  {
    path: '/company/:id',
    name: 'CompanyView',
    component: () => import('@/views/companies/CompanyView'),
  },
  {
    path: '/company/create',
    name: 'CompanyCreate',
    component: () => import('@/views/companies/CompanyEdit'),
  },
  {
    path: '/company/:id/edit',
    name: 'CompanyEdit',
    component: () => import('@/views/companies/CompanyEdit'),
  },
  {
    path: '/user',
    name: 'UserList',
    component: () => import('@/views/users/UserList'),
  },
  {
    path: '/user/:id',
    name: 'UserView',
    component: () => import('@/views/users/UserView'),
  },
  {
    path: '/user/:id/edit',
    name: 'UserEdit',
    component: () => import('@/views/users/UserEdit'),
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: () => import('@/views/users/UserEdit'),
  },
  {
    path: '/user/:id/admin_reset_password',
    name: 'UserAdminResetPassword',
    component: () => import('@/views/users/UserAdminResetPassword'),
  },
  {
    path: '/warehouse',
    name: 'WarehouseList',
    component: () => import('@/views/warehouses/WarehouseList'),
  },
  {
    path: '/warehouse/:id',
    name: 'WarehouseView',
    component: () => import('@/views/warehouses/WarehouseView'),
  },
  {
    path: '/warehouse/:id',
    name: 'WarehouseEdit',
    component: () => import('@/views/warehouses/WarehouseEdit'),
  },
  {
    path: '/warehouse/create',
    name: 'WarehouseCreate',
    component: () => import('@/views/warehouses/WarehouseEdit'),
  },
  {
    path: '/403',
    name: 'Error403',
    component: () => import('@/views/Error'),
    meta: {
      allowAll: true,
      layout: 'empty-layout',
    }
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import('@/views/Error'),
    meta: {
      allowAll: true,
      layout: 'empty-layout',
    }
  },
  {
    path: '/500',
    name: 'Error500',
    component: () => import('@/views/Error'),
    meta: {
      allowAll: true,
      layout: 'empty-layout',
    }
  },
  {
    path: '*',
    redirect: {
      name: 'Error404'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.allowAll) && !store.getters['auth/isAuthenticated']) next('/login');
  if (to.matched.some(record => record.meta.onlyNotAuthenticated) && store.getters['auth/isAuthenticated']) next('/');
  next();
})

export default router
