import api from '@/api';

const order_path = '/stocks/order/';
const order_admin_path = '/stocks/order-admin/';
const stock_path = '/stocks/stock/';

export default {
  getAllStocks(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .get(stock_path, { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getAllOrders(company = "") {
    return new Promise((resolve, reject) => {
      api
        .get(order_path, { params: { company } })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getOrder(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`${order_path}${id}/`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  postOrder(payload) {
    return new Promise((resolve, reject) => {
      api
        .post(order_path, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  putOrder(payload) {
    return new Promise((resolve, reject) => {
      api
        .put(`${order_path}${payload.id}/`, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  patchOrder(payload) {
    return new Promise((resolve, reject) => {
      api
        .patch(`${order_path}${payload.id}/`, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  deleteOrder(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`${order_path}${id}/`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getAdminOrderList() {
    return new Promise((resolve, reject) => {
      api
        .get(order_admin_path)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getAdminOrder(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`${order_admin_path}${id}/`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
