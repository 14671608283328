<template>
  <v-snackbars :objects.sync="messages" />
</template>

<script>
import VSnackbars from "v-snackbars"

export default {
  name: "AlertSnackbar",
  components: {
    VSnackbars
  },
  computed: {
    messages: {
      get() {
        return this.$store.getters.alerts;
      },
      set(v) {
        this.$store.dispatch("removeAlert", v.key)
      },
    }
  },
};
</script>
