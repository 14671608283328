import catalog from '@/api/catalog';

const state = () => ({
  categories: [],
  devices: [],
  products: [],
})

const defaultCategories = [
  { title: 'Все категории', id: -1 },
]

const defaultDevices = [
  { title: 'Все устройства', id: -1 },
  { title: 'Без устройства', id: null }
]

const getters = {
  // !el.root_id для категорий по умолчнию
  getCategories: state => (all = false) => [...defaultCategories, ...state.categories].filter(el => all ? true : el.id === el.root_id || !el.root_id),
  getDevices: state => [...defaultDevices, ...state.devices],
  getProducts: state => state.products,
  getCategory: (state, getters) => id => {
    let finded = getters.getCategories(true).find((el) => el.id == id);
    return finded ? finded.root_title : '';
  },
  getDevice: (state, getters) => id => {
    let finded = getters.getDevices.find((el) => el.id == id);
    return finded ? finded.title : '';
  }
}

const actions = {
  updateCategories({ commit, state }) {
    if (state.categories.length === 0) {
      catalog.getAllCategory().then(
        response => commit('setCategories', response.data)
      );
    }
  },
  updateProducts({ commit }) {
    catalog.getAllProduct().then(
      response => commit('setProducts', response.data)
    );
  }
}
const mutations = {
  setProducts(state, payload) {
    state.products = payload;
  },
  setCategories(state, payload) {
    state.categories = [];
    payload.forEach(el => {
      state.categories.push(el)
    });
  },
  setDevices(state, payload) {
    state.devices = [];
    payload.forEach(el => {
      state.devices.push(el)
    });
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
